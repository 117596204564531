
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SigninAuthForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    Checkbox: () => import('~/components/atoms/Checkbox.vue'),
    Button: () => import('~/components/atoms/Button.vue'),
  },
  data: () => ({
    mask: '+# ### ###-####',
    phone: '+7 ',
    password: '',
    remember: false,
    isWasRemembered: false,
    authErr: '',
    passwordType: true,
    captchaData: {
      active: false,
      token: null,
      error: '',
    },
    isDisabled: false,
  }),
  computed: {
    ...mapGetters({
      RememberToken: 'remember/RememberToken',
      RememberPhone: 'remember/RememberPhone',
    }),

    isButtonDisabled() {
      return this.password.length === 0 || this.phone.length < 15 || this.isDisabled
    },
  },
  mounted() {
    if (this.RememberToken && this.RememberPhone) {
      this.remember = true
      this.isWasRemembered = true
      this.phone = this.RememberPhone
    } else {
      this.CLEAR_REMEMBER_DATA()
    }
  },

  methods: {
    ...mapActions({
      userAuthorization: 'signin/userAuthorization',
    }),
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_SIGNIN_TYPE: 'signin/SET_SIGNIN_TYPE',
      CLEAR_REMEMBER_DATA: 'remember/CLEAR_REMEMBER_DATA',
      SET_REMEMBER_TOKEN: 'remember/SET_REMEMBER_TOKEN',
      SET_REMEMBER_PHONE: 'remember/SET_REMEMBER_PHONE',
      SET_CART: 'cart/SET_CART',
      SET_PRODUCTS: 'cart/SET_PRODUCTS',
    }),

    /**
     * A set of methods for recaptcha listeners
     */
    captchaSuccess(token) {
      this.captchaData.token = token
      this.captchaData.error = ''
    },

    /**
     * Method change sign in type
     * @param {String} type - sign in type
     */
    changeType(type) {
      this.SET_SIGNIN_TYPE(type)
    },
    /**
     * Method authorizes user by data phone and password
     * If the user selected "Remember me", then remembers the phone and its token
     */
    async userLogin(evt) {
      evt.preventDefault()

      try {
        this.isDisabled = true
        if (!this.captchaData.token && this.captchaData.active) {
          this.captchaData.error = 'Пожалуйста докажите что вы не робот'
          return
        }

        if (!this.isWasRemembered || !this.remember) {
          const { phone, password } = this
          const parsedPhone = phone.replace(/[^\d]/g, '')

          const authRes = await this.userAuthorization({
            phone: parsedPhone,
            password,
          })

          if (authRes.errors) {
            this.$refs.form.setErrors(authRes.errors)
            const attemptsCount = +localStorage.getItem('wrongAttempts')
            localStorage.setItem('wrongAttempts', attemptsCount + 1 || 1)
            this.captchaData.active = attemptsCount >= 4
          } else if (authRes.data.status === 200) {
            await this.$auth.setUserToken(authRes.data.token)
            await this.$auth.fetchUser()

            if (this.remember) {
              this.SET_REMEMBER_TOKEN(authRes.data.token)
              this.SET_REMEMBER_PHONE(phone)
            } else {
              this.CLEAR_REMEMBER_DATA()
            }
            const { user } = this.$store.state.auth
            const cartId = user && user.cart ? user.cart.cart_id : null
            const products = user && user.cart ? user.cart.products : []

            this.SET_CART(cartId)
            this.SET_PRODUCTS(products)
            this.SET_SIGNIN_ACTIVE(false)

            localStorage.setItem('wrongAttempts', 0)
          }
        } else {
          await this.$auth
            .setUserToken(this.RememberToken)
            .then(() => {
              const { user } = this.$store.state.auth
              const cartId = user && user.cart ? user.cart.cart_id : null
              const products = user && user.cart ? user.cart.products : []

              this.SET_CART(cartId)
              this.SET_PRODUCTS(products)
            })
            .catch(() => {
              this.authErr =
                'Произошла ошибка в сохранениии, попробуйте снять галочку "Запомнить меня"'
            })

          this.SET_SIGNIN_ACTIVE(false)
        }
      } finally {
        this.isDisabled = false
        await this.$store.dispatch('rph-users/getUser', {
          id: this.$store.state.auth.user.id,
        })
        this.$nextTick(() => {
          this.$nuxt.$loading.finish()
        })
        if (this.captchaData.active) {
          this.$recaptcha.reset()
        }
      }
    },
    /**
     * Method logout user, set general token for axios and close modal
     */
    logout() {
      this.$auth.logout()
      this.$axios.setToken('cUN5-MNgeC6NY-nG_edUpAijsPfzT32H', 'Bearer')
      this.SET_SIGNIN_ACTIVE(false)
    },
    /* Запрещаем удалять маску this.phone */
    readonlyMask($event) {
      if (/^\+\d+ $/.test(this.phone) && $event.key === 'Backspace') {
        $event.preventDefault()
      }
    },
    /* Запрещаем перемещать курсор в маске this.phone */
    setFocus($event) {
      $event.preventDefault()
    },
    /* Запрещаем перемещать курсор на +7  */
    setCursor($event) {
      $event.preventDefault()
      document.querySelector("input[type='tel']").setSelectionRange(4, 4)
    },
  },
}
