
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

/**
 * @vue-prop {String} phoneProp - phone for pre-fill data phone value
 * @vue-prop {Boolean} checkExist - enable check exist phone
 */
export default {
  name: 'SigninConfirmPhone',
  components: {
    ValidationObserver,
    ValidationProvider,
    Input: () => import('~/components/atoms/Input.vue'),
    Button: () => import('~/components/atoms/Button.vue'),
    ButtonTimeout: () => import('~/components/atoms/ButtonTimeout.vue'),
  },
  props: {
    phoneProp: {
      type: String,
      default: '',
    },
    checkExist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mask: '+7 ### ###-####',
    phone: '+7',
    code: '',
    phoneIsSended: false,
    codeIsCorrect: false,
    btnTimeoutActive: true,
    btnDisabled: false,
  }),

  computed: {
    ...mapGetters({
      ModalType: 'signin/ModalType',
    }),
  },
  mounted() {
    if (this.phoneProp) {
      this.phone = this.phoneProp
    }
  },
  methods: {
    ...mapActions({
      getCodeByPhone: 'signin/getCodeByPhone',
      sendPhoneCode: 'signin/sendPhoneCode',
    }),
    /**
     * Method send phone for get verificztion sms code
     */
    async checkPhone() {
      this.btnDisabled = true
      this.$refs.confirmPhoneForm.setErrors({ code: [] })
      this.$refs.confirmPhoneForm.reset()
      this.$refs.confirmPhoneForm.errors.code = []
      await this.$nextTick()
      if (this.$refs.confirmPhoneForm.flags.valid) {
        const { phone, checkExist } = this

        try {
          const checkPhoneRes = await this.getCodeByPhone({
            phone,
            exist: checkExist ? 1 : 0,
          })

          if (checkPhoneRes.errors) {
            this.$refs.confirmPhoneForm.setErrors(checkPhoneRes.errors)
            this.phoneIsSended = false
            this.btnDisabled = false
          } else if (checkPhoneRes.data.status === 200) {
            this.phoneIsSended = true
            this.btnDisabled = false
          }

          return true
        } catch (err) {
          throw new Error(err)
        }
      }
    },
    /**
     * Method send verificztion sms code and return phone if it is correct
     */
    async sendCode() {
      if (!this.code) this.$refs.confirmPhoneForm.setErrors({ code: ['Введите код'] })
      if (this.$refs.confirmPhoneForm.flags.valid) {
        const { phone, code } = this

        if (phone && code) {
          try {
            const codeRes = await this.sendPhoneCode({
              phone,
              code,
            })

            if (codeRes.errors) {
              this.$refs.confirmPhoneForm.setErrors(codeRes.errors)
              this.$emit('is-correct-phone', false)
            } else if (codeRes.data.status === 200) {
              this.codeIsCorrect = true
              this.$emit('is-correct-phone', phone)
            }
          } catch (err) {
            throw new Error(err)
          }
        }
      }
    },
  },
}
